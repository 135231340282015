:root {
    --light-grey: #F3F3F3;
    --large-margin: 20%;
    --font-bold: 'Neue Haas Grotesk Disp W02 Blk';
  }
  
  @include mobile{
    :root {
      --large-margin: 20px;
    }
  
  }
  