
@font-face {
  font-family: 'Times Now';
  src: url('../fonts/Times-Now.eot');
  src: url('../fonts/Times-Now.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Times-Now.woff2') format('woff2'),
      url('../fonts/Times-Now.woff') format('woff'),
      url('../fonts/Times-Now.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body {
  font-family: 'Times Now', serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
